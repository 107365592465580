import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

const JobsCarouselItem = ({ image1, image2, image3, imgClassName }) => {
  return (
    <Grid container direction="row" justify="space-between">
      <Grid item xs={4}>
        <img
          src={image1}
          alt="jobs"
          height="auto"
          width="100%"
          className={imgClassName}
        />
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <img
          src={image2}
          alt="jobs"
          height="auto"
          width="100%"
          className={imgClassName}
        />
      </Grid>
      <Grid item xs={4}>
        <img
          src={image3}
          alt="jobs"
          height="auto"
          width="100%"
          className={imgClassName}
        />
      </Grid>
    </Grid>
  );
};

JobsCarouselItem.propTypes = {
  image1: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  image3: PropTypes.string.isRequired,
  imgClassName: PropTypes.string.isRequired,
};

export default JobsCarouselItem;
